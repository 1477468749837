.main {
  width: 100%;
  padding-top: 90px;
  padding-bottom: 70px;
}

.main .flights {
  width: 100%;
  -webkit-box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px 25px;
  border-radius: 25px;
}

.main .flights h2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 40px;
  color: #605dec;
  font-size: 30px;
}

.main .flights label {
  font-size: 12px;
}

.main .flights label p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main .flights label p svg {
  margin-left: 5px;
  font-size: 18px;
  fill: #3392ff;
}

.main .flights label .passengers {
  width: 100%;
  border: 1px solid black;
  height: 44px;
  border-radius: 25px;
  margin-top: 5px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.main .flights label .passengers:hover {
  background-color: #605dec;
  border: 1px solid #605dec;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.main .flights label .passengers:hover h3 {
  color: white;
}

.main .flights label .passengers h3 {
  font-size: 14px;
  font-weight: 400;
}

.main .flights label input {
  width: 100%;
  height: 42px;
  padding: 10px 15px;
  border-radius: 25px;
  border: 1px solid black;
  margin-top: 5px;
}

.gradient-canvas {
  --gradient-color-1: #1762b2;
  --gradient-color-2: #4b27ed;
  --gradient-color-3: #15a283;
  --gradient-color-4: #3c36d5;
}
.gradient-canvasJd {
  --gradient-color-1: #cd602a;
  --gradient-color-2: #eb8c25;
  --gradient-color-3: #f0b728;
  --gradient-color-4: #af7305;
}
.gradient-canvasHotel {
  --gradient-color-1: #BC827E;
  --gradient-color-2: #C18183;
  --gradient-color-3: #BE8084;
  --gradient-color-4: #926D9A;
}

.main .flights label input::-webkit-input-placeholder {
  font-size: 12px;
}

.main .flights label input:-ms-input-placeholder {
  font-size: 12px;
}

.main .flights label input::-ms-input-placeholder {
  font-size: 12px;
}

.main .flights label input::placeholder {
  font-size: 12px;
}

.main .flights button.find {
  width: 100%;
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 10px 0px;
  cursor: pointer;
  background-color: #605dec;
  color: white;
  border: none;
  border-radius: 25px;
  border: 1px solid #605dec;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  font-weight: 500;
}

.main .flights button.find:hover {
  background-color: white;
  color: #605dec;
  border: 1px solid #605dec;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  font-weight: 500;
}

.searchDataList2,
.searchDataList,
.searchModalDataList2,
.searchModalDataList {
  position: absolute;
  top: calc(100% + 4px);
  max-height: 400px;
  overflow-y: scroll;
  left: 0px;
  width: 150%;
  background-color: #fff;
  box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.searchModalDataList2,
.searchModalDataList {
  position: absolute;
  top: calc(100% + 4px);
  max-height: 400px;
  overflow-y: scroll;
  left: 0px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.aviaTicket .checklistContainer::-webkit-scrollbar {
  width: 5px;
  top: 16px;
}

.searchDataList2::-webkit-scrollbar,
.searchDataList::-webkit-scrollbar {
  width: 0;
}

.searchModalDataList2::-webkit-scrollbar,
.searchModalDataList::-webkit-scrollbar {
  width: 2px;
}

.searchDataList2 div,
.searchDataList div,
.searchModalDataList div,
.searchModalDataList2 div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  /* border-bottom: 1px solid #CCC; */
  width: 100%;
}
.searchDataList div:hover,
.searchDataList2 div:hover,
.searchModalDataList div:hover,
.searchModalDataList2 div:hover {
  /* background-color: #3379cb; */
  width: 100%;
  /* color: #fff !important; */
  /* cursor: pointer; */
  transition: 0.1s ease;
}

.searchDataList div:hover .cityCode,
.searchDataList2 div:hover .cityCode,
.searchModalDataList div:hover .cityCode,
.searchModalDataList2 div:hover .cityCode {
  color: #fff;
}

.searchDataList div:last-child,
.searchDataList2 div:last-child,
.searchModalDataList div:last-child,
.searchModalDataList2 div:last-child {
  border: none !important;
}

.main .css-19kzrtu {
  padding: 0;
}

/* .ticketToInput::placeholder {
  color: red;
} */

.main .rounded-Main-container {
  filter: blur(5px);
  margin: 0;
  line-height: 5em;
}

.main .rounded-Main {
  z-index: 1;
  clip-path: circle(56.7% at 85% 74%);
  overflow: hidden;
  background-color: #fff;
  opacity: 5%;
  margin: 0;
}

.main .rounded-Main-mask .ocean {
  border-top-left-radius: 70%;
  border-bottom-left-radius: 15%;
}
/* 
.main .rounded-Main::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
  opacity: 5%;
  -moz-filter: blur(10px);
  -webkit-filter: blur(10px);
  -o-filter: blur(10px);
  filter: blur(10px);
  transform: scale(2.5);
  z-index: 1;
  filter: blur(10px);  
} */

.ocean {
  position: relative;
}

.bubble {
  border-radius: 100%;
  position: absolute;
  background-color: white;
  opacity: 0.2;
}

.container.show {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1) !important;
}

.css-16mozzi {
  width: 18px !important;
  height: 18px !important;
}
.css-16mozzi::before {
  width: 18px !important;
  height: 18px !important;
}

.main-Anim-Parent
  .css-1f2kvjf-MuiFormControlLabel-root
  .MuiFormControlLabel-label,
.main-Anim-Parent
  .css-1f2kvjf-MuiFormControlLabel-root
  .MuiFormControlLabel-label {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
  font-weight: 300;
}
.google-login {
  height: 0 !important;
}

.searchLineMobile {
  grid-template-columns: 30% 10% 30% 30%;
}

/* common styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  color: #2c2c2c;
}

a {
  color: inherit;
  text-decoration: none;
}

.btn {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  transition-delay: 0s;

  padding: 10px 20px;
  margin-right: 10px;

  background-color: #fff;
  border: 1px solid #2c2c2c;
  border-radius: 3px;

  cursor: pointer;
  outline: none;
}

.btn:last-child {
  margin-right: 0;
}

.btn:hover,
.btn.js-active {
  color: #fff;
  background-color: #2c2c2c;
}

/* header styles */
.header {
  max-width: 500px;
  margin: 50px auto;
  text-align: center;
}

.header__title {
  margin-bottom: 30px;
  font-weight: 500;
}

/* content styles */
.content {
  max-width: 700px;
  margin: auto;
}

.content__title {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

.content__inner {
  width: 375px;
  height: 450px;
  margin: auto;

  box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}

/* tabs styles */
.tabs {
  position: relative;
  padding: 15px 0;
  height: 100%;
  /* overflow: hidden; */
}

.tabs .tabs-nav-container {
  width: 350px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px); /* Safari uchun */
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.085);
}

/* tabs nav styles */
.tabs__nav {
  position: relative;
}

/* nav decoration is appended by js */
.tabs__nav-decoration {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  transition: width 0.2s linear 0s, transform 0.2s ease-out 0s;
  background-color: #00A8FF;
  border-radius: 6px;
  z-index: 1;
  width: 100%;
}

.tabs__nav-list {
  position: relative;
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  z-index: 5;
}

.tabs__nav-item {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  transition-delay: 0s;
  padding: 10px;
  cursor: pointer;
  gap: 5px;
  align-items: center;
  justify-content: center;
}

/* active styles */
.tabs__nav-item.js-active {
  transition-delay: 0.05s;
  color: #fff;
}

.tabs__panels {
  position: relative;
  margin-top: 30px;
}

.tabs__panel {
  position: absolute;
  top: 0;
  left: 0;
  transition: none;
  transform: scale(0.8);
  width: 100%;
  opacity: 0;
}

/* active panel styles */
.tabs__panel.js-active {
  transition: all 0.25s linear 0s;
  transform: scale(1);
  opacity: 1;
}

@media (max-width: 576px) {
  .css-gz20x9-MuiSvgIcon-root {
    display: none !important;
  }
}

@media screen and (max-width: 420px) {
  .rmdp-wrapper.rmdp-shadow {
    zoom: 0.9;
  }
}

@media screen and (max-width: 370px) {
  .rmdp-wrapper.rmdp-shadow {
    zoom: 0.8 !important;
  }
}
/*# sourceMappingURL=Main.css.map */
