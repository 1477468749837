.hotel-map .loader {
    width: 200px;
    height: 200px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    z-index: 100;
}

.hotel-map .element {
    border-radius: 100%;
    border: 3px solid #0057BE;
    margin: 7px;
}

.hotel-map .element:nth-child(1) {
    animation: preloader 0.6s ease-in-out alternate infinite;
}

.hotel-map .element:nth-child(2) {
    animation: preloader 0.6s ease-in-out alternate 0.2s infinite;
}

.hotel-map .element:nth-child(3) {
    animation: preloader 0.6s ease-in-out alternate 0.4s infinite;
}


@keyframes preloader {
    100% {
        transform: scale(2);
    }
}